
import { Options, Vue } from 'vue-class-component'
import AdminUsers from '@/components/Users/AdminUsers/index.vue' // @ is an alias to /src

@Options({
  components: {
    AdminUsers
  }
})
export default class UsersView extends Vue {}
